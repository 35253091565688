import {
    sellerLeadPath,
    vdBodyTypesPath,
    vdMakesPath,
    vdModelsPath,
    vdYearsPath,
    vehicleLeadPath
} from '../../lib/api/Api';

const API = {
    makes: (year: any, startYear = 2014) => `${vdMakesPath}?${createSearchParams({year, startYear})}`,
    models: (makeId: any, year: any, startYear: any = null) => `${vdModelsPath}?${createSearchParams({
        makeId,
        year,
        startYear
    })}`,
    years: (makeId: any, modelName: any) => `${vdYearsPath}?${createSearchParams({makeId, modelName})}`,
    bodyTypes: `${vdBodyTypesPath}`,
    vehicleLead: `${vehicleLeadPath}`,
    sellerLead: `${sellerLeadPath}`
};

const createSearchParams = (obj: any) => {
    Object.keys(obj).forEach(key => {
        if (!obj[key]) {
            delete obj[key];
        }
    });

    return new URLSearchParams(obj);
};

const getYearsRange = (start: any, stop: any) => Array(Math.ceil((stop - start + 1))).fill(start).map((x, y) => x + y).reverse();

const getMakes = async (year: any) => {
    return await fetch(API.makes(year), {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET'
    })
    .then(response => {
        return response.json();
    }).catch(error => {
        throw new Error(error);
    });
};

const getModels = (makeId: any, year: any) => {
    return fetch(API.models(makeId, year), {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET'
    })
    .then(response => {
        return response.json();
    }).catch(error => {
        throw new Error(error);
    });
};

const getYears = (makeId: any, modelName: any) => {
    return fetch(API.years(makeId, modelName), {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET'
    })
    .then(response => {
        return response.json();
    }).catch(error => {
        throw new Error(error);
    });
};

const getBodyTypes = async () => {
    return await fetch(API.bodyTypes, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET'
    })
    .then(response => {
        return response.json();
    }).catch(error => {
        throw new Error(error);
    });
};

const createOption = (label: any, value: any) => {
    const option = document.createElement('option');
    option.innerHTML = label;
    option.value = value;
    return option;
};

const formatCurrency = (value: any = 0, prefix = '', suffix = '', currency = 'USD', language = 'en-US') => {
    const formatter = new Intl.NumberFormat(language, {
        currencyDisplay: 'symbol',
        currencySign: 'accounting',
        ...(prefix ? {} : {style: 'currency', currency}),
        minimumFractionDigits: 0
    });

    return `${value < 0 ? '-' : ''}${prefix || ''}${formatter.format(Math.abs(value))} ${suffix}`.trim();
};

const escapeString = (name: string) => {
    return name.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '');
};

export const browseMarketplaceByLocation = (lat: any, lng: any, address_type: any = 'colloquial_area') => {
    return `/marketplace?${createSearchParams({
        sort: 'relevance',
        lat: lat,
        lng: lng,
        addressType: address_type
    })}`;
};

export const browseMarketplaceByBodyType = (bodyTypes: any, name: string) => {
    const bodyType = bodyTypes?.find((bodyType: { id: number; name: string; }) => escapeString(name).includes(escapeString(bodyType.name)));
    return `/marketplace?${createSearchParams({
        sort: 'relevance',
        vdBodyTypeIds: bodyType?.id
    })}`;
};

const years = getYearsRange(2014, new Date().getFullYear() + 1);

class ConsumerForm {
    form: any;
    makeSelect: any;
    modelSelect: any;
    makeId: any;
    makeName: any;
    makes: any;
    modelName: any;
    modelId: any;
    year: any;
    router: any;
    submitButton: any;

    constructor(form: any, router: any) {
        this.form = form;
        this.router = router;
        this.makeSelect = form.querySelector(`[data-type="make"]`);
        this.modelSelect = form.querySelector(`[data-type="model"]`);
        this.submitButton = form.querySelector(`[data-type="submit-button"]`);
        this.onMakeSelectHandeler = this.onMakeSelectHandeler.bind(this);
        this.onModelSelectHandeler = this.onModelSelectHandeler.bind(this);
        this.onSubmitHandeler = this.onSubmitHandeler.bind(this);
    }

    onMakeSelectHandeler(e: any) {
        this.makeId = e.target.value || null;
        this.makeName = this.makes.find((make: any) => make.id == this.makeId)?.name || null;
        if (this.makeId) {
            this.getModels();
        } else {
            this.createModelOptions([]);
        }
    }

    async onModelSelectHandeler(e: any) {
        const modelName = e.target.value;
        this.modelName = modelName || null;
    }

    onSubmitHandeler(e: any) {
        e.preventDefault();
    }

    clearSelect(select: any) {
        let options = select.querySelectorAll('option');
        for (let i = 0; i < options.length; i++) {
            !(options[i].disabled) && !(options[i].value == '') && options[i].remove(i);
        }
        options[0].selected = 'selected';
    }

    createMakeOptions(makes: any) {
        this.clearSelect(this.makeSelect);
        makes.forEach((make: any) => {
            const option = createOption(make.name, make.id);
            this.makeSelect.appendChild(option);
        });
    }

    createModelOptions(models: any) {
        this.clearSelect(this.modelSelect);
        this.modelId = null;
        this.modelName = null;
        models.forEach((model: any) => {
            const option = createOption(model.name, model.name);
            this.modelSelect.appendChild(option);
        });
    }

    async getModels() {
        const models = await getModels(this.makeId, this.year);
        this.createModelOptions(models);
    }

    async getMakes(year = null) {
        const makes = await getMakes(year);
        this.makes = makes;
        this.createMakeOptions(makes);
    }

    init() {
        this.getMakes(this.year);
        this.makeSelect.addEventListener('change', this.onMakeSelectHandeler);
        this.modelSelect.addEventListener('change', this.onModelSelectHandeler);
        this.form.addEventListener('submit', this.onSubmitHandeler);
    }
}

export class BuyerForm extends ConsumerForm {
    locationInput: any;
    latLng: any;
    addressType: any;

    constructor(form: any, router: any) {
        super(form, router);
        this.locationInput = form.querySelector(`[data-type="location"]`);
        this.init();
    }

    onSubmitHandeler(e: any) {
        super.onSubmitHandeler(e);
        const redirect = () => {
            let url = `/marketplace?${createSearchParams({
                sort: 'relevance',
                lat: this.latLng?.lat,
                lng: this.latLng?.lng,
                vdMakeIds: this.makeId,
                makeName: this.makeName,
                vdModels: this.modelName,
                addressType: this.addressType
            })}`;
            this.router.push(url);
        };
        let interval: any;

        if (this.locationInput.value && !this.latLng) {
            const latLng = this.latLng;
            interval = setTimeout(() => {
                if (latLng) {
                    clearInterval(interval);
                    redirect();
                }
            }, 100);
        } else {
            interval && clearInterval(interval);
            redirect();
        }
    }

    initAddressAutocomplete() {
        const input = this.locationInput;
        const options = {
            componentRestrictions: {country: 'ca'},
            fields: ['name', 'formatted_address', 'geometry', 'icon', 'address_component', 'type'],
            strictBounds: false
        };
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener('place_changed', () => {
            const place: any = autocomplete.getPlace();
            if (!place.geometry) return;
            this.latLng = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            this.addressType = place.types[0];
        });
    }

    init() {
        super.init();
        this.initAddressAutocomplete();
    }
}

export class SellerForm extends ConsumerForm {
    yearSelect: any;

    constructor(form: any, router: any) {
        super(form, router);
        this.yearSelect = form.querySelector(`[data-type="year"]`);
        this.onYearSelectHandeler = this.onYearSelectHandeler.bind(this);
        this.init();
    }

    async onModelSelectHandeler(e: any) {
        super.onModelSelectHandeler(e);
        if (this.year) {
            this.submitButton.disabled = true;
            const trims = await getYears(this.makeId, this.modelName);
            this.submitButton.disabled = false;
            this.modelId = trims.find((trim: any) => trim.year == this.year)?.id;
        }
    }

    async onYearSelectHandeler(e: any) {
        this.year = e.target.value;
        this.getMakes(e.target.value);
        this.createModelOptions([]);
    }

    onSubmitHandeler(e: any) {
        const router = this.router;
        super.onSubmitHandeler(e);
        this.submitButton.disabled = true;
        fetch(API.vehicleLead, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                vdModelId: this.modelId,
                isTrimUnknown: true
            })
        })
        .then(response => {
            return response.json();
        }).then(json => {
            if (json.id) {
                router.push(`/vehicle-lead/${json.id}?step=0`);
            }
            this.submitButton.disabled = false;
        }).catch(error => {
            this.submitButton.disabled = false;
            throw new Error(error);
        });
    }

    createYearOptions() {
        this.yearSelect.options.length === 1 && years.forEach(year => {
            const option = createOption(year, year);
            this.yearSelect.appendChild(option);
        });
    }

    init() {
        super.init();
        this.createYearOptions();
        this.yearSelect.addEventListener('change', this.onYearSelectHandeler);
    }
}

export class DealerCalculator {
    amount = 15;
    form: any;
    formContainer: any;
    tableContainer: any;
    editButton: any;
    inspection_profit: any;
    dealerships: any;
    backend_profit: any;
    recon_profit: any;
    dealer_fees: any;

    constructor(container: any) {
        this.form = container.querySelector('form');
        this.formContainer = container.querySelector('.profit-calculator');
        this.tableContainer = container.querySelector('.profit-table');
        this.editButton = container.querySelector('.edit-numbers');
        this.onSubmitHandeler = this.onSubmitHandeler.bind(this);
        this.onEditButtonClickHandeler = this.onEditButtonClickHandeler.bind(this);
        this.init();
    }

    calculate() {
        const sale_percent = 1;
        const sold_vehicles = this.amount * sale_percent;
        const inspection_profit_monthly = this.inspection_profit * this.amount * this.dealerships;
        const backend_profit_monthly = this.backend_profit * sold_vehicles * this.dealerships;
        const recon_profit_monthly = this.recon_profit * sold_vehicles * this.dealerships;
        const dealer_fees_monthly = this.dealer_fees * sold_vehicles * this.dealerships;
        const total_monthly = inspection_profit_monthly + backend_profit_monthly + recon_profit_monthly + dealer_fees_monthly;
        const result = {
            inspection_profit: this.inspection_profit,
            backend_profit: this.backend_profit,
            recon_profit: this.recon_profit,
            dealer_fees: this.dealer_fees,

            inspection_profit_monthly: inspection_profit_monthly,
            backend_profit_monthly: backend_profit_monthly,
            recon_profit_monthly: recon_profit_monthly,
            dealer_fees_monthly: dealer_fees_monthly,

            inspection_profit_yearly: inspection_profit_monthly * 12,
            backend_profit_yearly: backend_profit_monthly * 12,
            recon_profit_yearly: recon_profit_monthly * 12,
            dealer_fees_yearly: dealer_fees_monthly * 12,

            total_monthly: total_monthly,
            total_annual: total_monthly * 12
        };
        this.renderTable(result);
    }

    renderTable(result: any) {
        for (const [key, value] of Object.entries(result)) {
            let elements = document.querySelectorAll(`[data-type="${key}"]`);
            elements.forEach(element => {
                element.innerHTML = formatCurrency(value);
            });

        }
    }

    onSubmitHandeler(e: any) {
        e.preventDefault();
        this.inspection_profit = parseInt((document.getElementById('profit') as HTMLInputElement)?.value);
        this.backend_profit = parseInt((document.getElementById('avg_back_profit') as HTMLInputElement)?.value);
        this.recon_profit = parseInt((document.getElementById('avg_recon_profit') as HTMLInputElement)?.value);
        this.dealerships = parseInt((document.getElementById('dealerships') as HTMLInputElement)?.value);
        this.dealer_fees = parseInt((document.getElementById('dealer_fees') as HTMLInputElement)?.value);
        this.calculate();
        this.formContainer.style.display = 'none';
        this.tableContainer.style.display = 'block';
    }


    onEditButtonClickHandeler(e: any) {
        e.preventDefault();
        this.formContainer.style.display = 'block';
        this.tableContainer.style.display = 'none';
    }

    init() {
        var that = this;
        document.addEventListener('onRangeChange', (e: any) => {
            const value = e.detail.value;
            const amountElements = document.querySelectorAll('.amount');
            amountElements.forEach(amountElement => {
                amountElement.innerHTML = value;
            });
            that.amount = parseInt(value);
            that.calculate();
        });

        const amountElements = document.querySelectorAll('.amount');
        amountElements.forEach(amountElement => {
            amountElement.innerHTML = `${that.amount}`;
        });
        this.form.addEventListener('submit', this.onSubmitHandeler);
        this.editButton.addEventListener('click', this.onEditButtonClickHandeler);
    }
}

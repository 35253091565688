import NextLink, { LinkProps } from "next/link";
import { HTMLAttributes, useContext } from "react";
import { InitialDataContext } from "../../contexts/init/InitialDataContext";

type CustomLinkProps = LinkProps & HTMLAttributes<HTMLAnchorElement>;

function Link(props: CustomLinkProps) {
  const {host} = useContext(InitialDataContext)
  return (
    <NextLink
      {...props}
      href={typeof props.href === "string" ? (host ?? '') + props.href : props.href}
    ></NextLink>
  );
}

export default Link;
